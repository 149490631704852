<template>
  <v-dialog v-model="dialog" width="800">
    <perseu-card :title="titleDialog">
      <template slot="content">
        <v-form ref="form" lazy-validation>
          <v-switch
            v-model="point.hasSides"
            label="Esse ponto possui lado Direito ou Esquerdo?"
          />
          <v-text-field
            v-model.trim="point.name"
            label="Nome do ponto"
            :rules="[$rules.required]"
          />
          <v-select
            v-model="point.type"
            label="Tipo"
            :items="types"
            item-text="label"
            item-value="value"
            :rules="[$rules.required]"
          />
          <v-flex class="my-3">
            <span>Imagem:</span>
          
            <aws-s3-image
            v-model="point.image"
            class="my-3"
            label="Imagem"
          />
          </v-flex>
          <v-textarea
            v-model.trim="point.imageDescription"
            label="Descrição da imagem"
            no-resize
            rows="4"
            outlined
          />
          <select-lang v-model="point.lang" :rules="[$rules.required]" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn color="secondary" @click="submitForm">Salvar</v-btn>
      </template>
    </perseu-card>
  </v-dialog>
</template>

<script>
import { save } from "@/services/points-service";
export default {
  components: {
    "aws-s3-image": () => import("@/components/Points/AwsInputImage"),
  },
  data: () => ({
    types: [
      { label: "Rastreio", value: "0" },
      { label: "Impactação", value: "1" },
    ],
    dialog: false,
    point: {},
  }),
  computed: {
    titleDialog() {
      return (this.point.id ? "Edição de" : "Novo") + " Ponto";
    },
  },
  methods: {
    create() {
      this.point = {};
      this.dialog = true;
      this.$refs.form?.resetValidation();
    },
    edit(item) {
      this.point = item;
      this.dialog = true;
    },
    async submitForm() {
      try {
        this.$store.dispatch("loading/openDialog");
        if (!this.$refs.form.validate()) return;
        const response = await save(this.point);
        if (this.point.id) {
          this.$emit("update", response.data);
        } else {
          this.$emit("create", response.data);
        }
        this.dialog = false;
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
